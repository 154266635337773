import { useState, useEffect } from "react"
import { useUpdateGameMutation, useDeleteGameMutation } from "./gameApiSlice"
import { useGetTeamsQuery } from "../teams/TeamApiSlice"
import { useNavigate } from "react-router-dom"

const EditGameForm = ({ game }) => {


    const [updateGame, {
        isLoading,
        isSuccess
    }] = useUpdateGameMutation()

    //eslint-disable-next-line
    const [deleteGame, {
        isSuccess: isDelSuccess
    }] = useDeleteGameMutation()

    const navigate = useNavigate()

    //eslint-disable-next-line
    const [gameId, setGameId] = useState(game.id)
    const [team1, setTeam1] = useState(game.team1)
    const [team2, setTeam2] = useState(game.team2)
    const [dateTime, setDateTime] = useState(game.dateTime)
    const [team1Game1Score, setTeam1Game1Score] = useState(game.team1Game1Score)
    const [team1Game2Score, setTeam1Game2Score] = useState(game.team1Game2Score)
    const [team1Game3Score, setTeam1Game3Score] = useState(game.team1Game3Score)
    const [team2Game1Score, setTeam2Game1Score] = useState(game.team2Game1Score)
    const [team2Game2Score, setTeam2Game2Score] = useState(game.team2Game2Score)
    const [team2Game3Score, setTeam2Game3Score] = useState(game.team2Game3Score)
    const [type] = useState(game.type)
    const [round] = useState(game.round)
    const [match] = useState(game.match)
    const leagueId = sessionStorage.leagueId

    useEffect((id) => {
        if (isSuccess || isDelSuccess) {
            id = leagueId
            navigate(`/dash/leagues/${id}/teams/schedule`)
        }
    })

    const onTeam1Changed = e => setTeam1(e.target.value)
    const onTeam2Changed = e => setTeam2(e.target.value)
    const onDateTimeChanged = e => setDateTime(e.target.value)
    const onTeam1Game1ScoreChanged = e => setTeam1Game1Score(e.target.value)
    const onTeam1Game2ScoreChanged = e => setTeam1Game2Score(e.target.value)
    const onTeam1Game3ScoreChanged = e => setTeam1Game3Score(e.target.value)
    const onTeam2Game1ScoreChanged = e => setTeam2Game1Score(e.target.value)
    const onTeam2Game2ScoreChanged = e => setTeam2Game2Score(e.target.value)
    const onTeam2Game3ScoreChanged = e => setTeam2Game3Score(e.target.value)


    const canSave = [team1, team2, dateTime].every(Boolean) && !isLoading

    const onSaveGameClicked = async (e) => {
        if (canSave) {
            await updateGame({ id: gameId, team1, team2, dateTime, team1Game1Score, team1Game2Score, team1Game3Score, team2Game1Score, team2Game2Score, team2Game3Score, type, round, match })
        }
    }

    const onDeleteGameClicked = async (e) => {
        if (canSave) {
            await deleteGame({ id: gameId })
        }
    }

            //getting teams
            const { teams }  = useGetTeamsQuery("teamsList", {
                selectFromResult: ({ data }) => ({
                    teams: data?.entities
                }),
            }) 
                //filtering down to an array of team names
            const filteredTeams = Object.values(teams).filter((league) => league.league === leagueId)
            const sortedArray = filteredTeams.sort((a,b) => (a.id > b.id) ? 1 : -1)
            let teamArray = sortedArray.map((teamName) => teamName.teamName)
            teamArray.push("BYE")

            //team options for form select
            const teamOptions = teamArray.map(team => {
                return (
                    <option
                        key={team}
                        value={team}
                    > {team}</option>
                )
            })

    const gameDateTime = new Date(dateTime).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', hour: "2-digit", minute: "2-digit" })

    const content = (
        <>
            <h2><span className="league-title">Edit Game: </span>{team1} - {team2}</h2> 
            <div className="sub-links">
                <h5>Game Time: {gameDateTime}</h5>
                <form className="form" onSubmit={e => e.preventDefault()}>
                <label className="form__label" htmlFor="team1">
                    Team 1:</label>
                <select
                    id="team1"
                    name="team1"
                    className=""
                    value={team1}
                    onChange={onTeam1Changed}
                >
                    {teamOptions}
                </select>

                <label className="form__label" htmlFor="team2">
                    Team 2:</label>
                <select
                    id="team2"
                    name="team2"
                    className=""
                    value={team2}
                    onChange={onTeam2Changed}
                >
                    {teamOptions}
                </select>


                <label className="form__label" htmlFor="dateTime">
                    Start Date/Time:</label>
                <input
                    className=""
                    type="datetime-local"
                    id="dateTime"
                    name="dateTime"
                    value={dateTime}
                    onChange={onDateTimeChanged}
                />

                {/* team 1 scores */}
                <label className="form__label" htmlFor="team1Score">
                    {game.team1} Score:</label>
                <input
                    className=""
                    id="team1Game1Score"
                    name="team1Game1Score"
                    type="number"
                    value={team1Game1Score}
                    onChange={onTeam1Game1ScoreChanged}
                    placeholder="Game 1"
                    onWheel={(e) => e.target.blur()}
                />
                <input
                    className=""
                    id="team1Game2Score"
                    name="team1Game2Score"
                    type="number"
                    value={team1Game2Score}
                    onChange={onTeam1Game2ScoreChanged}
                    placeholder="Game 2"
                    onWheel={(e) => e.target.blur()}
                />
                <input
                    className=""
                    id="team1Game3Score"
                    name="team1Game3Score"
                    type="number"
                    value={team1Game3Score}
                    onChange={onTeam1Game3ScoreChanged}
                    placeholder="Game 3"
                    onWheel={(e) => e.target.blur()}
                />

                {/* team 2 scores */}
                <label className="form__label" htmlFor="team2Score">
                    {game.team2} Score:</label>
                <input
                    className=""
                    id="team2Game1Score"
                    name="team2Game1Score"
                    type="number"
                    value={team2Game1Score}
                    onChange={onTeam2Game1ScoreChanged}
                    placeholder="Game 1"
                    onWheel={(e) => e.target.blur()}
                />
                <input
                    className=""
                    id="team2Game2Score"
                    name="team2Game2Score"
                    type="number"
                    value={team2Game2Score}
                    onChange={onTeam2Game2ScoreChanged}
                    placeholder="Game 2"
                    onWheel={(e) => e.target.blur()}
                />
                <input
                    className=""
                    id="team2Game3Score"
                    name="team2Game3Score"
                    type="number"
                    value={team2Game3Score}
                    onChange={onTeam2Game3ScoreChanged}
                    placeholder="Game 3"
                    onWheel={(e) => e.target.blur()}
                />
                <div>
                    <button
                            title="Save"
                            className="session-update-button"
                            onClick={onSaveGameClicked}
                            disabled={!canSave}
                        >
                            Update Game
                        </button>
                        <button
                            title="Delete"
                            className="session-delete-button"
                            onClick={onDeleteGameClicked}
                        >
                            Update Game
                        </button>
                    </div>
                </form>
            </div>
        </>
    )

    return content
}

export default EditGameForm