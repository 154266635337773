import LeagueRulesCSixes from "./LeagueRulesCSixes"
import LeagueRulesC5s from "./LeagueRulesC5s"
import LeagueRulesCQuads from "./LeagueRulesCQuads"
import LeagueRulesDSixes from "./LeagueRulesDSixes"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"

const LeagueRules = () => {

    const { isSVL } = useAuth()
    const navigate = useNavigate()
    const leagueURL = window.location.pathname.slice(0, -12)
    
    const backToLeague = (e) => {
        e.preventDefault()
        navigate(leagueURL.toString())
    }
    
    return (
        <>
            <h3 className="league-title">All League Rules</h3>

            <div className="league-links">
                {!isSVL && <p onClick={backToLeague}>Back to League</p>}
                {isSVL && <p onClick={backToLeague}>Back to Leauge</p>}
            </div>

            <div className="league-rules">
            <ul>
                <li>Must be 18 years or older to play</li>
                <li>Calls made by our referees are final. Please do not question their judgment. They are doing their best and people make mistakes. If there are any questions or concerns about officiating please contact Collin Sheehan (csheehan@volleysub.com)</li>
                <li>If you will not have enough players to meet the league minimum number of players and need to forfeit, please notify Volleysub at least 2 hours in advance of your scheduled game time</li>
                <li>To make sure games start on time, games will be played once a minimum number of players have arrived. There will be a 10 minute grace period before a forfeit is declared</li>
                <li>Matches will consist of 3 sets with the first two sets going to 21 (cap 23) and the third set going to 15 (cap at 17). Win by two unless cap is reached</li>
                <li>All three sets will be played during regular season while tournament matches will be best of 3</li>
                <li>“Lifts”, “carries”, “throws”, are not allowed and will be called at the discretion of the referee</li>
                <li>Net touches are not allowed</li>
                <li>Blocks do not count as one of your 3 touches</li>
                <li>The centerline under the net is a neutral zone that both teams can occupy, crossing over the centerline is allowed so long as you do not interfere with the opponent</li>
                <li>The net posts will serve as antennas if there are none present. The ball must be entirely within the posts when crossing the net</li>
                <li>Pursuit of the ball across the centerline is not allowed</li>
                <li>Pursuit of the ball into other courts is not allowed unless court is completely unoccupied</li>
                <li>Volleysub provided league ball will be used for match unless both teams agree on another ball</li>
                <li>Children are not allowed in the play area. The play area is the court and the 10 feet surrounding on each side</li>
                <li>Players should play in leagues that correspond to their skill level. If not, Volleysub will ask players to move up or down leagues</li>
                <li>End of season tournaments are single elimination, seeding will be based on regular season record, tiebreakers will be head to head result, if not head to head result then a flip of a coin will decide higher seed</li>
                <li>Tournament subs must have played at least one game with your team during the regular season. If you have not needed a sub prior to the tournament, please contact Volleysub</li>
                <li>League fees must be paid and waivers must be signed by all team members prior to first game of the season</li>
                <li>Teams are allowed one 30 second timeout per set</li>
            </ul>
            </div>
            <LeagueRulesC5s />
            <LeagueRulesCSixes />
            <LeagueRulesCQuads />
            <LeagueRulesDSixes />
        </>
    )
}

export default LeagueRules
