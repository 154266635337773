
const LeagueRulesC5s = () => {
    return (
        <>
            <div>
                <details>
                    <summary className="league-title">League Rules - C 5s</summary>
                        <div className="league-rules">
                        <ul>
                            <li>Teams must have at least three players to play with at least 2 women unless otherwise specified by league</li>
                            <li>No more than three men are allowed on the court for a team at any time unless otherwise specified by league</li>
                            <li>No jump serving</li>
                            <li>When attacking the ball, back row players must be at least 10 feet from the net or remain grounded</li>
                            <li>Double contacts on sets are allowed</li>
                        </ul>
                        </div>
                </details>
            </div>
        </>
    )
}

export default LeagueRulesC5s